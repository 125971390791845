@keyframes damageEffect {
  0% {
    background: radial-gradient(
      circle,
      rgba(255, 0, 0, 1) 0%,
      rgba(255, 0, 0, 0.8) 50%,
      rgba(255, 0, 0, 0) 100%
    );
    transform: scale(1);
  }
  100% {
    background: radial-gradient(
      circle,
      rgba(255, 0, 0, 0) 0%,
      rgba(255, 0, 0, 0) 50%,
      rgba(255, 0, 0, 0) 100%
    );
    transform: scale(0.8);
    opacity: 0;
  }
}

@keyframes healEffect {
  0% {
    background: radial-gradient(
      circle,
      rgba(0, 255, 0, 1) 0%,
      rgba(0, 255, 0, 0.8) 50%,
      rgba(0, 255, 0, 0) 100%
    );
    transform: scale(1);
  }
  100% {
    background: radial-gradient(
      circle,
      rgba(0, 255, 0, 0) 0%,
      rgba(0, 255, 0, 0) 50%,
      rgba(0, 255, 0, 0) 100%
    );
    transform: scale(1.2);
    opacity: 0;
  }
}

.damage,
.heal {
  animation-duration: 0.3s;
  animation-fill-mode: forwards; /* アニメーション終了時のスタイルを保持 */
}

.damage {
  animation-name: damageEffect;
}

.heal {
  animation-name: healEffect;
}
