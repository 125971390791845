.poison-custom-button {
  font-size: 0.8rem;
  padding: 0 0.4rem;
  margin: 0.2rem 0.5rem;
  border-radius: 50px;
}

.poison-counter {
  font-size: 1rem;
  color: black;
  margin-top: 0.1rem;
}
