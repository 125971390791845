.player-left {
  color: white;
}

.player-right {
  color: white;
}

.background {
  background-image: url("./images/background.png");
  background-size: 100% auto;
  background-position: top;
}

.footer {
  display: flex;
  height: 8vh;
  background-color: rgb(142, 101, 6, 0.9);
  color: white;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  bottom: 0;
  z-index: 1;
}
